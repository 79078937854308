import { db } from '../firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import MunicipalStore from '../stores/MunicipalStore';

const host = window.location.host;
const subdomain = host.split('.')[0];
const subdomainCapitalized = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

export const getMunicipality = async () => {
    if (host === 'localhost:8100' || host === 'localhost:3000' || subdomain === 'www') {
        console.log('getting doc using subdomain static value "Whitestone"');
        const docRef = doc(db, 'municipalities', 'Whitestone');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log('Document data:', docSnap.data());
            MunicipalStore.update((s) => {
                s.municipality = docSnap.data();
            });
            document.title = 'Localhost | GetPermits.Online';
        } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
        }
    } else {
        console.log('getting doc using subdomain', subdomain);
        const docRef = doc(db, 'municipalities', subdomainCapitalized);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log('Document data:', docSnap.data());
            MunicipalStore.update((s) => {
                s.municipality = docSnap.data();
            });
            document.title = docSnap.data().name + ' | GetPermits.Online';
        } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
        }
    }
};

export const getPermitTypes = async () => {
    const permitTypes = [];
    if (host === 'localhost:8100' || host === 'localhost:3000' || subdomain === 'www') {
        // console.log('getting permit types for static value "Whitestone"');
        const querySnapshot = await getDocs(
            collection(db, 'municipalities/Whitestone/permitTypes')
        );
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data());
            permitTypes.push(doc.data());
        });
        MunicipalStore.update((s) => {
            s.permitTypes = permitTypes;
        });
    } else {
        // console.log('getting permit types for', subdomainCapitalized);
        const querySnapshot = await getDocs(
            collection(db, 'municipalities/' + subdomainCapitalized + '/permitTypes')
        );
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data());
            permitTypes.push(doc.data());
        });
        MunicipalStore.update((s) => {
            s.permitTypes = permitTypes;
        });
    }
};
