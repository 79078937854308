import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import './Menu.css';
import MunicipalStore from '../stores/MunicipalStore';
import { useStoreState } from 'pullstate';
import { useEffect } from 'react';
import AppStore from '../stores/AppStore';
const Menu = () => {
    const location = useLocation();
    const { municipality, permitTypes } = useStoreState(MunicipalStore);
    const { appName, environment, domain, path } = useStoreState(AppStore);
    useEffect(() => {
        console.log('permitTypes ', permitTypes);
    }, [permitTypes]);
    useEffect(() => {
        console.log('municipality ', municipality);
    }, [municipality]);
    useEffect(() => {
        console.log('environment ', environment);
        console.log('domain ', domain);
        console.log('path ', path);
    }, [environment, domain, path]);
    return (
        <IonMenu contentId='main' type='overlay'>
            <IonContent>
                {' '}
                <div className='menuItems'>
                    <IonList id='inbox-list'>
                        <IonListHeader>{appName}</IonListHeader>
                        <IonNote>{municipality?.name}</IonNote>

                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                className={location.pathname === '/home' ? 'selected' : ''}
                                routerLink={'/home'}
                                routerDirection='none'
                                lines='none'
                                detail={false}
                            >
                                <ion-icon slot='start' name={`home-outline`}></ion-icon>
                                <IonLabel>Home</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                        {permitTypes?.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem
                                        className={
                                            location.pathname === appPage?.url ? 'selected' : ''
                                        }
                                        routerLink={appPage?.url}
                                        routerDirection='none'
                                        lines='none'
                                        detail={false}
                                    >
                                        <ion-icon
                                            slot='start'
                                            name={`${appPage.icon}-outline`}
                                        ></ion-icon>
                                        <IonLabel>{appPage?.name}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}
                    </IonList>
                    <IonList id='labels-list'>
                        <IonListHeader>My Account</IonListHeader>
                        {/* {labels.map((label, index) => (
                        <IonItem lines='none' key={index}>
                            <IonIcon slot='start' icon={bookmarkOutline} />
                            <IonLabel>{label}</IonLabel>
                        </IonItem>
                    ))} */}
                    </IonList>
                </div>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
