//pullstate store for municipal data
import { Store } from 'pullstate';
const AppStore = new Store({
    appName: 'GetPermits.Online',
    environment: null,
    domain: null,
    path: null,
});

export default AppStore;
