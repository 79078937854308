import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useLocation } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect } from 'react';
import { getMunicipality, getPermitTypes } from './functions/utilities';
import AppStore from './stores/AppStore';
setupIonicReact();

const App = () => {
    const location = useLocation();
    const environment = process.env.NODE_ENV;
    const domain = window.location.hostname;
    useEffect(() => {
        getMunicipality();
        getPermitTypes();
        AppStore.update((s) => {
            s.environment = environment;
            s.domain = environment === 'production' ? domain : 'localhost';
            s.path = location.pathname;
        });
    });

    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId='main'>
                    <Menu />
                    <IonRouterOutlet id='main'>
                        <Route path='/' exact={true}>
                            <Redirect to='/' />
                        </Route>
                        <Route path='/:name' exact={true}>
                            <Page />
                        </Route>
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
